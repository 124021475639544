button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

input {
  background: none;
  border: none;
  outline: none;
}

#lsg-chatbot-cta-container {
  z-index: 9999;
  width: 9rem;
  height: 9rem;
  position: fixed;
  bottom: 3.75rem;
  right: 6.25rem;
}

@media (width <= 500px) {
  #lsg-chatbot-cta-container {
    bottom: 1rem;
    right: 1rem;
  }
}

button#lsg-chatbot-cta {
  cursor: pointer;
  z-index: 99999;
  background: linear-gradient(#000020 0%, #24243a 31%, #737373 100%);
  border: 2px solid #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all 1.4s cubic-bezier(.4, 0, 1, 1);
  position: relative;
  overflow: hidden;
}

button#lsg-chatbot-cta.hide, .cleo .outer-circle.hide, .cleo-image-popup.hide {
  display: none;
}

button#lsg-chatbot-cta:hover {
  transform: rotate(360deg);
  box-shadow: 0 0 1rem #00000080;
}

button#lsg-chatbot-cta:after {
  content: "";
  opacity: .5;
  background-color: #0000;
  background-image: url("chatbot-btn.3ee5cad8.157c8804.gif"), linear-gradient(#000020 0%, #24243a 31%, #737373 100%);
  background-position: 0 0, 0 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 50%;
  width: 11.125rem;
  height: 11.125rem;
  position: absolute;
  top: -1rem;
  left: -1rem;
}

.cleo-image-popup {
  width: 9.75rem;
  height: 9.75rem;
  transition: all .5s ease-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

button#lsg-chatbot-cta:hover ~ .cleo-image-popup.can-show {
  top: -4rem;
}

.cleo {
  opacity: 0;
  color: #fff;
  background: linear-gradient(#100c1b 0%, #413d3f 100%);
  border-radius: 2.1875rem;
  width: 19.875rem;
  height: 38.6875rem;
  transition: opacity 1s ease-in-out;
  position: absolute;
  bottom: 3.25rem;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

.cleo.show {
  opacity: 1;
}

.cleo .outer-circle {
  background: #34303566;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 15.5rem;
  height: 15.5rem;
  display: flex;
  position: absolute;
  bottom: -6.5625rem;
  left: 50%;
  transform: translateX(-50%);
}

.cleo .outer-circle .inner-circle {
  background: #58515a8c;
  border-radius: 100%;
  width: 11.75rem;
  height: 11.75rem;
}

.cleo .cleo-image {
  width: 15.25rem;
  height: 15.25rem;
}

.cleo #cleo-stage-0, .cleo #cleo-stage-1, .cleo #cleo-stage-2, .cleo #cleo-stage-3 {
  display: none;
}

.cleo #cleo-stage-0.show {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  display: flex;
}

.cleo #cleo-stage-1.show {
  padding: 5rem 2rem;
  display: block;
}

.cleo #cleo-stage-2.show {
  padding: 6.625rem 2rem;
  display: block;
}

.cleo #cleo-stage-3.show {
  display: block;
}

.cleo #cleo-stage-1 .how-can-help {
  margin-top: .5rem;
  margin-bottom: 2rem;
}

.cleo h3 {
  width: 100%;
  margin-bottom: .5rem;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.25rem;
}

.cleo h3 span {
  font-weight: 300;
}

.cleo #cleo-stage-2 h3 {
  margin-bottom: .5rem;
}

.cleo #cleo-stage-2 h3 span {
  font-weight: 300;
}

.cleo #cleo-stage-2 h3 b {
  font-weight: 600;
}

.cleo #cleo-stage-2 .menu {
  justify-content: space-between;
  margin-top: 3.25rem;
  margin-bottom: 1.75rem;
  display: flex;
}

.cleo #cleo-stage-2 .menu .menu-left {
  cursor: pointer;
  background: #4c426180;
  border-radius: .9375rem .9375rem .9375rem 2.5rem;
  width: 7.75rem;
  padding: 1.5rem .875rem;
  transition: transform .3s, box-shadow .3s;
  animation: 1.5s infinite pulse, .5s infinite wiggle;
}

.cleo #cleo-stage-2 .menu .menu-left:hover {
  animation-play-state: paused;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 4px 8px #0003;
  }

  50% {
    transform: scale(1.05);
    box-shadow: 0 6px 12px #0000004d;
  }
}

@keyframes wiggle {
  0%, 100% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(2deg);
  }

  75% {
    transform: rotate(-2deg);
  }
}

.cleo #cleo-stage-2 .menu .menu-left img {
  width: 2.5rem;
  height: 2.5rem;
}

.cleo #cleo-stage-2 .menu .menu-left p {
  color: #fff;
  margin-top: .5rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25rem;
}

.cleo #cleo-stage-2 .menu .menu-right {
  width: 7rem;
}

.cleo #cleo-stage-2 .menu .menu-right-up {
  background: #56418166;
  border-radius: .9375rem;
  height: 5.25rem;
  padding: .875rem;
}

.cleo #cleo-stage-2 .menu .menu-right-bottom {
  background: #43267e4d;
  border-radius: 15px 15px 40px;
  height: 5.5rem;
  margin-top: .5rem;
}

.cleo #cleo-stage-2 .menu .menu-right img {
  width: 2.5rem;
  height: 2.5rem;
}

.cleo p {
  color: #fff;
  font-size: .875rem;
  line-height: 1.2rem;
}

.cleo .widget-head {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.back-btn img {
  width: 1.5rem;
  height: 1.5rem;
}

.expand-btn img {
  width: 1.25rem;
  height: 1.25rem;
}

label {
  color: #fff;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  font-size: .75rem;
  display: flex;
}

.cleo #onboarding-form {
  flex-direction: column;
  gap: 1.25rem;
  display: flex;
}

.cleo #onboarding-form input {
  color: #fff;
  background: #24202a66;
  border: 1px solid #272329;
  border-radius: .7rem;
  padding: .8rem 1rem;
}

.cleo #onboarding-form .form-btns {
  justify-content: center;
  width: 100%;
  display: flex;
}

.cleo #onboarding-form button, .cleo #cleo-stage-0 button {
  color: #fff;
  background: #1e1a25;
  border: 1px solid #fff;
  border-radius: 4rem;
  align-items: center;
  column-gap: .375rem;
  padding: .5rem 2.625rem;
  font-size: .625rem;
  display: flex;
}

.cleo #cleo-stage-0 button {
  margin-top: 1rem;
}

.cleo #bottom-shade {
  opacity: 0;
  background: #ffffff0d;
  width: 100%;
  height: 3.75rem;
  transition: all 1s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cleo #bottom-shade.show {
  opacity: 1;
}

.cleo #onboarding-form button img {
  height: .5rem;
}

.recent-activities {
  height: 6.875rem;
  overflow-y: auto;
}

.recent-activities p {
  font-size: .875rem;
  font-weight: 300;
}

.recent-activities a {
  font-size: .7rem;
  text-decoration: underline;
}

.recent-activities .activities {
  flex-direction: column;
  gap: .875rem;
  margin-top: .875rem;
  display: flex;
}

.recent-activities .activity {
  background: #9592961a;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: .875rem .625rem;
  display: flex;
}

.recent-activities .activity p {
  font-size: .65rem;
}

.recent-activities .activity > div {
  gap: .5rem;
  display: flex;
}

.recent-activities .activity img.chat {
  height: 1.125rem;
}

.recent-activities .activity img.ellipsis {
  height: .2rem;
}

.cleo #cleo-stage-3.show .chat-box {
  background: linear-gradient(#fefefe 0%, #9a9a9a 100%);
  border-radius: 22px 22px 0 0;
  flex-direction: column;
  justify-content: flex-end;
  height: 31.4375rem;
  margin-top: 3.75rem;
  display: flex;
}

.cleo #cleo-stage-3.show #chat-box-input {
  color: #363030;
  background: #d1d1d1;
  border: none;
  border-radius: 10px 10px 0 0;
  outline: none;
  width: 100%;
  padding: .875rem .625rem;
  font-size: .875rem;
  box-shadow: 3px 199px 12.2px 206px #00000040;
}

.cleo #cleo-stage-3.show #chat-box-input::placeholder {
  color: #6c6060;
}

.cleo #cleo-stage-3.show .chat-box .chats {
  flex-direction: column;
  row-gap: .625rem;
  padding: .875rem;
  display: flex;
}

.cleo #cleo-stage-3.show .chat-box .message {
  display: flex;
}

.cleo #cleo-stage-3.show .chat-box .message.user {
  justify-content: flex-end;
}

.cleo #cleo-stage-3.show .chat-box .message p {
  color: #000;
  border: 1px solid #696464;
  padding: .5rem .7rem;
  font-size: .75rem;
}

.cleo #cleo-stage-3.show .chat-box .message.user p {
  border-radius: .5rem .5rem 0;
}

.cleo #cleo-stage-3.show .chat-box .message.bot p {
  background: #fff;
  border-radius: .5rem .5rem .5rem 0;
}
/*# sourceMappingURL=index.63bf8d6c.css.map */
